<template>

  <div class="mt-1">

    <Menu></Menu>

    <div class="parent">

      <iframe allow="fullscreen" allowfullscreen v-show="isLoaded" id="i-frame-id" class="iframe-container" v-bind:src="gameURL"></iframe>

    </div>

  </div>

</template>
<script>

import Menu from "@/components/casino/Menu";
import endorphina from "@/services/endorphina";

export default {
  name: 'EndorphinaGames',
  components: {
    Menu
  },
  data: function () {
    return {
      loading:'',
      gameName: '',
      icon:"assets/casino/jetx.jpeg",
      isLoaded: false,
      gameURL: '',
      playMode: 'real',
      iframeID: '',
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","endorphinagames");
    this.reloadProfile();

    var vm = this;

    if(this.playMode === 'exit') {

      setInterval(function () {

        vm.isLoaded =true;

        if(document.getElementById('i-frame-id')) {

          var frame1 = document.getElementById('i-frame-id');
          frame1.parentNode.removeChild(frame1);
          vm.$router.push({ name: 'Endorphina', params: { } });

        } else {

          console.log('cannot find i-frame-id')

        }
      },1000)
    }
  },
  computed: {

    app: function () {

      return this.$store.state.app;

    },

    profile: function() {

      return this.getProfile();

    },
    token: function(){

      return this.getAuth();
    },

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        //JetX
        // react to route changes...
        this.gameName = to.params.gameName === undefined ? '' : to.params.gameName;
        this.gameID = to.params.gameID === undefined ? '' : to.params.gameID;
        this.playMode = to.params.playMode === undefined ? 'real' : to.params.playMode;

        if(this.gameID.length === 0 ) {

          this.isLoaded = true;

          if(document.getElementById('i-frame-id')) {

            var frame = document.getElementById('i-frame-id');
            frame.parentNode.removeChild(frame);

          } else {

            console.log('cannot find i-frame-id')
          }
          //
          this.$router.push({ name: 'Endorphina', params: { } });

        }

        if(this.playMode === "demo") {

          this.gameURL = to.params.gameURL === undefined ? '' : to.params.gameURL;
          this.isLoaded = true;

        } else if(this.playMode === "exit") {

          this.isLoaded = true;

          if(document.getElementById('i-frame-id')) {

            var frame1 = document.getElementById('i-frame-id');
            frame1.parentNode.removeChild(frame1);

          } else {

            console.log('cannot find i-frame-id')

          }

          this.$router.push({ name: 'Endorphina', params: { } });

        } else {

          this.isLoaded = false;
          this.getGameURL(this.gameID)

        }
      }
    },
  },
  methods: {

    goToCasino: function(){

      this.$router.push({ name: 'casino', params: { } });

    },
    showDeposit: function() {

      document.getElementById("deposit-init").click();

    },
    getGameURL: function (gameID) {

      if(gameID === undefined || gameID.length === 0 ) {

        this.goTo('Endorphina')
        //this.$router.push({ name: 'Endorphina', params: { } });
        return
      }

      var tokn = this.getFasoAuth();

      if(tokn === undefined || tokn.length === 0 ) {

        this.goTo('Endorphina')
        return;
        //this.$router.push({ name: 'Endorphina', params: { } });
      }

      var path = process.env.VUE_APP_ENDORPHINA_START_GAME_URL;
      var data = {
        game: gameID,
        token: tokn
      }

      var vm = this;
      vm.loading = 'loading';
      vm.isLoaded = false;

      endorphina.post(path, JSON.stringify(data))
          .then(res => {

            vm.loading = 'loading';
            vm.isLoaded = true;
            var dat = res.data;
            vm.gameURL = dat.game_url;

          })
          .catch(err => {

            vm.loading = '';

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    getKey: function(){

      this.iframeID =  Math.random().toString(10).replace('0.', 'iframe-id-');

    },
  },
  filters: {

    amount: function (val){

      if(val === undefined ) {

        return val

      }

      return parseFloat(val).toFixed(2).toLocaleString()

    }

  }
}
</script>

<style scoped>
.title_name{
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  background: #1b5733;
  width: 100%;
  height: 48px;

}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4{
  position: relative;
  top: .6em;
  right: 1em;
}
</style>